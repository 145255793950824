<script setup lang="ts">
import type { Promotion } from "@/types";

const { t } = useT();

defineProps<{
	type?: Promotion["type"];
	image: string;
	title: string;
	subTitle: string;
}>();

const { isFlipTheCoinAvailable, typeValue, reset } = useFlipTheCoin();

watch(
	() => typeValue.value,
	(value) => {
		if (value) {
			reset(value);
		}
	}
);
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 328,
			height: 257,
			src: image,
			format: 'webp',
			alt: 'flip-the-coin'
		}"
		height="257px"
	>
		<template #default>
			<ABadge background="var(--casab)" variant="skew" class="top-left" autosize>
				<AText variant="topeka" :modifiers="['bold', 'uppercase']">{{ t("Personal offer") }}</AText>
			</ABadge>
		</template>
		<template #top>
			<div class="block">
				<AText variant="ternopil" :modifiers="['normal', 'uppercase']" class="title">
					{{ title }}
				</AText>
				<AText variant="topeka" :modifiers="['bold', 'uppercase']" class="sub-title"> {{ subTitle }} </AText>
				<AText
					as="div"
					variant="tanzay"
					gradient="var(--ghaziabad)"
					:modifiers="['black', 'uppercase']"
					class="text-tlalnepantla content"
				>
					<span>{{ t("Lucky Offers") }}</span>
				</AText>
			</div>
		</template>
		<template #bottom>
			<AButton :variant="!isFlipTheCoinAvailable ? 'disabled' : 'primary'" class="promo" data-tid="promo-flip-coin">
				<AText variant="toledo" :modifiers="['bold', 'uppercase']">
					<template v-if="isFlipTheCoinAvailable">{{ t("Flip the coin") }}</template>
					<template v-else>
						<AText variant="tulsa">
							<NuxtIcon name="20/clock" filled />
						</AText>
						{{ t("Available tomorrow") }}
					</template>
				</AText>
			</AButton>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.card {
	&::before {
		content: "";
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		position: absolute;
		z-index: 0;
		background-color: var(--curug);
	}

	&:deep(.top),
	&:deep(.bottom) {
		align-items: flex-start;
	}

	.title {
		position: absolute;
		top: 42px;
		left: 20px;
		max-width: 100%;
	}

	.sub-title {
		display: block;
		min-height: 15px;
		margin-bottom: gutter(0.5);
	}

	.content {
		max-width: 130px;
	}

	.count-before {
		display: inline-flex;
		max-width: 40px;
	}

	.sweep-stakes {
		margin-bottom: gutter(3);
	}
}

.card-tournament {
	&.card-entries .coins,
	&:not(.card-entries) .entries {
		display: none;
	}

	.turnament-disabled {
		cursor: not-allowed;
		opacity: 0.6;
	}
}

.top-left,
.top-right {
	position: absolute;
	top: 0;
	left: 0;
}

.top-right {
	left: auto;
	right: 0;
}

.card-count {
	display: flex;
	align-items: center;
	gap: gutter(0.5);
}

button {
	width: 240px;
	height: 40px;
	gap: gutter(0.75);
}
</style>
